import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"

import { rhythm } from "../utils/typography.js"

export default function Layout({ children }) {
  return (
    <div css={css``}>
      <div
        css={css`
          padding: ${rhythm(2)};
          padding-top: ${rhythm(1.5)};
          font-size: ${rhythm(0.75)};
          height: 100%;
          margin-left: auto;
          margin-right: auto;
        `}
      >
        <title
          css={css`
            flex-grow: 4;
            order: 1;
            margin-left: auto;
            margin-right: auto;
          `}
        >
          Josh Burns
        </title>

        <div
          css={css`
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
          `}
        >
          <Link to={`/`}>
            <h1
              css={css`
                display: inline-block;
                font-style: normal;
                margin-left: 50%;
                margin-right: 50%;
                max-width: 500px;
              `}
            >
              Josh Burns
            </h1>
          </Link>
          <br />
          <div>
            <Link
              to={`/contact/`}
              css={css`
                float: left;
                padding: 5px;
              `}
            >
              Contact
            </Link>
            <Link
              to={`/ideas/`}
              css={css`
                float: left;
                padding: 5px;
              `}
            >
              Ideas
            </Link>
            <Link
              to={`/blog/`}
              css={css`
                float: right;
                padding: 5px;
              `}
            >
              Blog
            </Link>
            <Link
              to={`/projects/`}
              css={css`
                float: right;
                padding: 5px;
              `}
            >
              Projects
            </Link>
          </div>
        </div>
        <br />
        <br />

        {children}
      </div>
    </div>
  )
}
